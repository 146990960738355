import { NewsCard } from '../news-item/news-item.component';
import { Component, OnInit } from '@angular/core';
import { EditPageSwitchService } from '../services/edit-page-switch.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  newsItems: NewsCard[] = [
    {title: 'Bio updated',
    date: '29.06.2018',
    description: `
    Tziplorem ipsum, blanao sit afk, blanone spliffulescu blana tziploriei. Jmekerao denghi blanonino hackerman
    bobao toblerone, marfa senki din alta tzarao, combinatzia aia blanonino.`},

    {title: 'Review received',
    date: '27.06.2018',
    description: 'Pretty bad imo.'},

    {title: 'Finished working on a project',
    date: '27.06.2018',
    description: `
    Tziplorem ipsum, blanao sit afk, blanone spliffulescu blana tziploriei. Jmekerao denghi blanonino hackerman
    bobao toblerone, marfa senki din alta tzarao, combinatzia aia blanonino.`},

    {title: 'Started a new project',
    date: '06.04.2018',
    description: `
    Tziplorem ipsum, blanao sit afk, blanone spliffulescu blana tziploriei. Jmekerao denghi blanonino hackerman
    bobao toblerone, marfa senki din alta tzarao, combinatzia aia blanonino.`},
  ];

  profilePicture = 'assets/shared/images/patel.jpg';
  username = 'Patel';
  projectsCompleted = 1;
  knowledge = [
      'Basic Webkit (HTML, CSS, JS)',
      'Cooking',
      'Good in Bed',
      'Lorem ipsum'
  ];

  constructor(private _switchService: EditPageSwitchService) {}
  fireSwitch(item: number) {
    console.log('selected page ' + item);
    this._switchService.changeItem(item);
  }

  ngOnInit() {
  }

}
