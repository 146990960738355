import { Component, OnInit, Input, ViewChild, ElementRef, NgModule, VERSION } from '@angular/core';
import { EditPageSwitchService } from '../../services/edit-page-switch.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})

export class EditProfileComponent implements OnInit {

  availab: any = 20;
  payment: any[] = new Array(20,50);

  editProfileForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.email, Validators.required]),
    phone: new FormControl(''),
    payment: new FormControl('')
  });

  get firstName() {
      return this.editProfileForm.get('firstName');
  }

  get lastName() {
      return this.editProfileForm.get('lastName');
  }

  get email() {
      return this.editProfileForm.get('email');
  }

  get phone() {
      return this.editProfileForm.get('phone');
  }

  @ViewChild('slider1', { read: ElementRef }) slider1: ElementRef;
  
  availabSliderConfig: any = {
      connect: [true, false],
      keyboard: true, // same as [keyboard]="true"
      step: 1,
      pageSteps: 10, // number of page steps, defaults to 10
      range: {
      min: 0,
      '60%': [30, 1],
      '85%': [60, 5],
      max: 100
      }
  };

  @ViewChild('slider2', { read: ElementRef }) slider2: ElementRef;
  
  paymentSliderConfig: any = {
      connect: [false, true, false],
      keyboard: true, // same as [keyboard]="true"
      step: 1,
      pageSteps: 10, // number of page steps, defaults to 10
      range: {
      min: [0,1],
      '50%': [100, 5],
      '70%': [250, 10],
      '85%': [500, 50],
      max: 1000
      }
  };
  
  constructor(private _switchService: EditPageSwitchService) {}
  fireSwitch(item: number) {
    console.log('selected page ' + item);
    this._switchService.changeItem(item);
  }

  ngOnInit() {
  }


}
