import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ProgrammerPageService } from './services/programmer-page.service';
import { BrowserModule } from '@angular/platform-browser';
import { NouisliderModule } from 'ng2-nouislider';
import { SliderModule } from 'angular-double-slider';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ProgrammerPageComponent } from './programmer-page/programmer-page.component';
import { CompanyPageComponent } from './company-page/company-page.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { CardComponent } from './card/card.component';
import { RatingComponent } from './rating/rating.component';
import { CollapsableCardComponent } from './collapsable-card/collapsable-card.component';
import { NewsItemComponent } from './news-item/news-item.component';
import { ProfileOptionsComponent } from './profile-options/profile-options.component';
import { FooterComponent } from './footer/footer.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { EditPageSwitchService } from './services/edit-page-switch.service';
import { SideFormWrapperComponent } from './side-form-wrapper/side-form-wrapper.component';
import { EditInfoPageComponent } from './edit-info-page/edit-info-page.component';
import { EditInfoNavComponent } from './edit-info-page/edit-info-nav/edit-info-nav.component';
import { EditProfileComponent } from './edit-info-page/edit-profile/edit-profile.component';
import { EditExperienceComponent } from './edit-info-page/edit-experience/edit-experience.component';
import { EditEducationComponent } from './edit-info-page/edit-education/edit-education.component';
import { EditQualificationsComponent } from './edit-info-page/edit-qualifications/edit-qualifications.component';
// import { ClickOutsideModule } from 'ng4-click-outside';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        LoginComponent,
        SignupComponent,
        ProgrammerPageComponent,
        CompanyPageComponent,
        ProfileComponent,
        HomeComponent,
        CardComponent,
        RatingComponent,
        CollapsableCardComponent,
        NewsItemComponent,
        ProfileOptionsComponent,
        FooterComponent,
        SearchBarComponent,
        ClickOutsideDirective,
        SideFormWrapperComponent,
        EditInfoPageComponent,
        EditInfoNavComponent,
        EditProfileComponent,
        EditExperienceComponent,
        EditEducationComponent,
        EditQualificationsComponent
    ],
    imports: [
        SliderModule,
        NouisliderModule,
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        // ClickOutsideModule,
        RouterModule.forRoot([
            { path: '', component: HomeComponent },
            { path: 'signup', component: SignupComponent },
            { path: 'login', component: LoginComponent },
            { path: 'profile', component: ProfileComponent },
            { path: 'profile-options', component: ProfileOptionsComponent },
            { path: 'programmer-page', component: ProgrammerPageComponent },
            { path: 'company-page', component: CompanyPageComponent },
            { path: 'edit-info-page', component: EditInfoPageComponent },
            { path: '**', component: HomeComponent },
        ])
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor , multi: true},
        AuthService,
        AuthGuard,
        ProgrammerPageService,
        EditPageSwitchService
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
