import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-form-wrapper',
  templateUrl: './side-form-wrapper.component.html',
  styleUrls: ['./side-form-wrapper.component.css']
})
export class SideFormWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
