import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditPageSwitchService } from '../../services/edit-page-switch.service';

@Component({
  selector: 'app-edit-education',
  templateUrl: './edit-education.component.html',
  styleUrls: ['./edit-education.component.css']
})
export class EditEducationComponent implements OnInit {

  editEduForm = new FormGroup({
    studyName: new FormControl(''),
    studyYear: new FormControl(''),
    specName: new FormControl('')
  });

  get studyName() {
    return this.editEduForm.get('studyName');
  }
  get studyYear() {
    return this.editEduForm.get('studyYear');
  }
  get specName() {
    return this.editEduForm.get('specName');
  }

  constructor(private _switchService: EditPageSwitchService) {}
  fireSwitch(item: number) {
    console.log('selected page ' + item);
    this._switchService.changeItem(item);
  }

  ngOnInit() {
  }

}
