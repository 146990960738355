import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { EditPageSwitchService } from '../../services/edit-page-switch.service';

@Component({
  selector: 'app-edit-info-nav',
  templateUrl: './edit-info-nav.component.html',
  styleUrls: ['./edit-info-nav.component.css']
})
export class EditInfoNavComponent implements OnInit {
  item = 0;

  constructor(private _switchService: EditPageSwitchService) {}
  fireSwitch(item: number) {
    console.log('selected page ' + item);
    this._switchService.changeItem(item);
  }

  ngOnInit() {
  }

}
