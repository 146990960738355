import { Component, OnInit } from '@angular/core';
import { Card } from '../card/card.component';
import { CollapsableCard } from '../collapsable-card/collapsable-card.component';

@Component({
  selector: 'app-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.css']
})
export class CompanyPageComponent implements OnInit {

  constructor() { }

  attributesTitle = 'Available knowledge:';
  leftButtonText = 'View User Profile';
  leftButtonLink = '';
  rightButtonText = 'Send Request';
  rightButtonLink = '';

  programmers: Card[] = [
    {picture: 'assets/shared/images/hackerman-icon.png',
    name: 'Hackerman',
    rating: 2.5,
    comment: 'Projects completed succesfully: 9001',
    title: 'Bio',
    attributesTitle: this.attributesTitle,
    attributes: [
      'Basic Webkit (HTML, CSS, JS)',
      'jQuery',
      'typeScript',
      'Angular',
      'React',
      'nodeJs',
      'C#',
      'Vue',
      'noSQL',
      'Ruby',
      'C++',
      'Java'
    ],
    description: `
    01000011 01100101 01101100 00100000 01101101 01100001 01101001 00100000 01110011
    01101101 01100101 01100011 01101000 01100101 01110010 00100000 01100100 01100101
    00100000 01110011 01101101 01100101 01100011 01101000 01100101 01110010 00100000
    01101101 01100001 01100110 01101001 01101111 01110100 00100000 01110000 01110010
    01101111 01100111 01110010 01100001 01101101 01100001 01110100 01101111 01110010`,
    leftButtonText: this.leftButtonText,
    leftButtonLink: this.leftButtonLink,
    rightButtonText: this.rightButtonText,
    rightButtonLink: this.rightButtonLink},

    {picture: 'assets/shared/images/patel.jpg',
    name: 'Patel',
    rating: 4.5,
    comment: 'Projects completed succesfully: 1',
    title: 'Bio',
    attributesTitle: this.attributesTitle,
    attributes: [
      'Basic Webkit (HTML, CSS, JS)',
      'Cooking',
      'Satisfy in bed',
      'Smart'
    ],
    description: `
    Very good programmer lorem ipsum dolor sit amet with lots of projects done,
    show bobs and vegana, consectetur adipiscing elit, sed do eiusmod tempor
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    leftButtonText: this.leftButtonText,
    leftButtonLink: this.leftButtonLink,
    rightButtonText: this.rightButtonText,
    rightButtonLink: this.rightButtonLink}
  ];

  myProjects: CollapsableCard[] = [
    {name: 'Senior Tech Support',
    comment: '$6/hr',
    title: 'Description',
    description: `
    Looking for Patels to solve this lorem ipsum dolor sit amet, consectetur adipiscing
    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    attributesTitle: 'Required Knowledge:',
    attributes: [
      'Basic Webkit (HTML, CSS, JS)',
      'Indian Language',
      'English Language',
      'How to use toilet',
      'Lorem Ipsum'
    ]},

    {name: 'Junior Angular Developer',
    comment: '$31/hr',
    title: 'Description',
    description: `
    Looking for programmers to build angular components like this lorem ipsum dolor sit amet,
    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    attributesTitle: 'Required Knowledge:',
    attributes: [
      'Basic Webkit (HTML, CSS, JS)',
      'typeScript',
      'nodeJs',
      'English Language',
      'Lorem Ipsum'
    ]},

    {name: 'Senior AI Engineer',
    comment: '$69/hr',
    title: 'Description',
    description: `
    Looking for AI specialist to work on our Google Assistant in order to make it the smartest lorem
    ipsum dolor sit amet on the planet consectetur adipiscing elit and take over the world by sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    attributesTitle: 'Required Knowledge:',
    attributes: [
      'Java',
      'C++',
      'C#',
      'Ruby',
      'All languages of the world'
    ]}
  ];
  ngOnInit() {
  }

}
