import { Component, OnInit, Input } from '@angular/core';

export class CollapsableCard {
  name: string;
  comment: string;
  title: string;
  description: string;
  attributesTitle: string;
  attributes: string[];
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'collapsable-card',
  templateUrl: './collapsable-card.component.html',
  styleUrls: ['./collapsable-card.component.css']
})

export class CollapsableCardComponent implements OnInit {

  @Input('cardData') cardData: CollapsableCard;
  @Input('cardIndex') i: number;
  constructor() { }

  ngOnInit() {
  }

}
