import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'app';
    ngOnInit() {
        firebase.initializeApp({
            apiKey: 'AIzaSyBa9utAL5GWN_ss0tngpCwMUlACK3aeOeQ',
            authDomain: 'ng-login-form.firebaseapp.com',
        });
    }
}
