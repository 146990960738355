import { AuthService } from '../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    formType: boolean;
    loginPath: string;

    constructor(private authService: AuthService) {

    }
    form = new FormGroup({
        email: new FormControl('', ),
        password: new FormControl('', )
    });

    toggleForm(showCompany: boolean) {
        this.formType = showCompany;
        if (this.formType) {
            this.loginPath = '/company-page';
        } else {
            this.loginPath = '/programmer-page';
        }
    }
    ngOnInit() {
        this.formType = false;
        this.loginPath = '/programmer-page';
    }

    login() {
        const email = this.form.get('email').value;
        const password = this.form.get('password').value;


        this.authService.SignInUser(email, password);
        const token = this.authService.token;
        console.log(token);
    }

    get username() {
        return this.form.get('username');
    }
    get password() {
        return this.form.get('password');
    }

}
