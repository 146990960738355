import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { EditPageSwitchService } from '../services/edit-page-switch.service';

@Component({
  selector: 'app-edit-info-page',
  templateUrl: './edit-info-page.component.html',
  styleUrls: ['./edit-info-page.component.css']
})

export class EditInfoPageComponent implements OnInit {

  activePage: boolean[];
  activeOpt: number;
  item: number;
  subscription: Subscription;

  constructor(private _switchService: EditPageSwitchService) { 
    this.activePage = new Array(true,false,false,false);
    this.activeOpt = 0;
  }

  ngOnInit() {
    this.subscription = this._switchService.switchItem$
       .subscribe(item => {this.item = item, this.switchPage()});
  }
  
  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscription.unsubscribe();
  }
  
  switchPage(target?:number){
    this.activePage[this.activeOpt]=false;
    this.activePage[this.item]=true;
    this.activeOpt=this.item;
  }

}
