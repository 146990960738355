import { AuthService } from './auth.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export class AuthInterceptor implements HttpInterceptor {
    // constructor(private authService:AuthService){

    // }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('Intercepted!', req);
        // tslint:disable-next-line:max-line-length
        // const copiedReq= req.clone({params: req.params.set('auth', this.authService.getToken())});  // to do when you get firebase support

        return next.handle(req);
    }
}
