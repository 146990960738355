import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient, private router: Router) {

    }

    token: string;
    parsedLanguages: string[];
    SignUpUser(email: string, passoword: string) {
        firebase.auth().createUserWithEmailAndPassword(email, passoword)
            .catch(
                error => console.log(error)
            );
    }
    SignInUser(email: string, password: string) {
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(
                response => {
                    console.log(response);
                    this.router.navigate(['/programmer-page']);
                    firebase.auth().currentUser.getIdToken()
                        .then(
                            (token: string) => this.token = token
                        );
                }
            )
            .catch(
                error => console.log(error)
            );
    }
    getToken() {
        firebase.auth().currentUser.getIdToken()
            .then(
                response => {
                    firebase.auth().currentUser.getIdToken()
                        .then(
                            (token: string) => this.token = token
                        )
                        .catch(
                            error => console.log(error)
                        );

                    return this.token;

                }
            );
    }
    isAuthenticated() {
        return this.token != null;
    }
    logout() {
        firebase.auth().signOut();
        this.token = null;
    }
    getLanguagesData() {

        this.http.get('https://ng-login-form.firebaseio.com/languages.json')
            .subscribe(
                (response) => {
                    console.log(response);

                    const languages = (((JSON.stringify(response)
                    .replace(/\"/g, ''))
                    .replace('[', ''))
                    .replace(']', ''))
                    .toString();

                    this.parsedLanguages = languages.split(',');
                    console.log(this.parsedLanguages);
                },
                (error) => console.log(error)
            );
            return this.parsedLanguages;
        }
    setLanguagesData(message) {

        return this.http.post('https://ng-login-form.firebaseio.com/languages.json?auth=' + this.token, JSON.stringify(message))
            .subscribe(
                (response) => {
                    console.log(response);
                },
                (error) => console.log(error)
            );
    }
}
