
export class ProgrammerPageService {

    toggle(language, languages) {
        switch (language) {
          case 'Basic Webkit (HTML, CSS, JS)':
            languages.basicWebkit = !languages.basicWebkit;
            break;

          case 'jQuery':
            languages.jQuery = !languages.jQuery;
            break;

          case 'typeScript':
            languages.typeScript = !languages.typeScript;
            break;

          case 'Angular':
            languages.angular = !languages.angular;
            break;

          case 'React':
            languages.react = !languages.react;
            break;

          case 'Vue':
            languages.vue = !languages.vue;
            break;

          case 'nodeJs':
            languages.nodeJs = !languages.nodeJs;
            break;

          case 'mySQL':
            languages.mySQL = !languages.mySQL;
            break;

          case 'noSQL':
            languages.noSQL = !languages.noSQL;
            break;

          case 'Ruby':
            languages.ruby = !languages.ruby;
            break;

          case 'C++':
            languages.cPlusPlus = !languages.cPlusPlus;
            break;

          case 'C#':
            languages.cSharp = !languages.cSharp;
            break;

          case 'Java':
            languages.java = !languages.java;
            break;

          case 'Perl':
            languages.perl = !languages.perl;
            break;

          default:

        }
    }
}
