import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {

  constructor() { }
  classArray = ['far fa-star','fas fa-star-half-alt','fas fa-star'];
  ratingArray = [0, 0, 0, 0, 0];
  // tslint:disable-next-line:no-input-rename
  @Input('ratingNr') rating: number;

  configureRating() {
    const stars = this.rating;
    let index = 0;
    for (let i = 0.5; i <= stars; i += 0.5) {
      this.ratingArray[index]++;

      if (Math.floor(i) === i) {
        index++;
      }
    }
  }

  updateRating(index) {
    for (let i = 0; i <= index; i++) {
      if (this.ratingArray[i] === 2 && (i === 4 || (this.ratingArray[i + 1] === 0 && index < i + 1))) {
        this.ratingArray[i] = 1;
      } else {
        this.ratingArray[i] = 2;
      }
    }

    for (let i = index + 1; i < 5; i++) {
      this.ratingArray[i] = 0;
    }
  }

  ngOnInit() {
    this.configureRating();
  }

}
