import { Component, OnInit, Input } from '@angular/core';

export class NewsCard {
  title: string;
  date: string;
  description: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.css']
})
export class NewsItemComponent implements OnInit {

  @Input('newsCard') newsCard: NewsCard;
  constructor() { }

  ngOnInit() {
  }

}
