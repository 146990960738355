import {Injectable, Output}      from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EditPageSwitchService {

  // @Output() switchEvent = new EventEmitter<any>();
  private _switchItemSource = new BehaviorSubject<number>(0);

  switchItem$ = this._switchItemSource.asObservable();

  changeItem(number){
    this._switchItemSource.next(number);
    // this.switchEvent.emit();
  }
}
