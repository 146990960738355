import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditPageSwitchService } from '../../services/edit-page-switch.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit-experience',
  templateUrl: './edit-experience.component.html',
  styleUrls: ['./edit-experience.component.css']
})
export class EditExperienceComponent implements OnInit {

  today = Date.now();
  stillWorking: boolean = false;
  expVar: number = 1;

  editExpForm = new FormGroup({
    jobName: new FormControl(''),
    companyName: new FormControl(''),
    periodWorkedBegin: new FormControl(''),
    periodWorkedEnd: new FormControl(''),
    jobDesc: new FormControl('')
  });

  get jobName() {
    return this.editExpForm.get('jobName');
  }

  get companyName() {
    return this.editExpForm.get('companyName');
  }

  get periodWorkedBegin() {
    return this.editExpForm.get('periodWorkedBegin');
  }

  get periodWorkedEnd() {
    return this.editExpForm.get('periodWorkedEnd');
  }

  get jobDesc() {
    return this.editExpForm.get('jobDesc');
  }

  changeVal(value) {
    this.stillWorking = value;
  }

  @ViewChild('sliderexp', { read: ElementRef }) sliderexp: ElementRef;
  
  expSliderConfig: any = {
      connect: [true, false],
      keyboard: true, // same as [keyboard]="true"
      step: 1,
      pageSteps: 10, // number of page steps, defaults to 10
      range: {
      min: 0,
      max: 2
      }
  };

  constructor(private _switchService: EditPageSwitchService) {}
  fireSwitch(item: number) {
    console.log('selected page ' + item);
    this._switchService.changeItem(item);
  }

  ngOnInit() {
  }

}
