// tslint:disable-next-line:quotemark
import { AbstractControl, ValidationErrors } from "@angular/forms";

// tslint:disable-next-line:class-name
export class UsernameValidators {

    static minLength(control: AbstractControl): ValidationErrors | null {
        const len = (control.value as string).length;
        const returnable = {minLength: {
            requiredLength: 5,
            actualLength: len }};
        if (len < 5) {
            return returnable;
        }
        return null;
    }

    static shouldBeUnique(control: AbstractControl): Promise<ValidationErrors | null> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (control.value === 'skylab') {
                    resolve({shouldBeUnique: true});
                } else {
                    resolve(null);
                }
            }, 2000);
        });
    }
}
