import { AuthService } from '../services/auth.service';
import { ProgrammerPageService } from '../services/programmer-page.service';
import { Component, OnInit } from '@angular/core';
import { Card } from '../card/card.component';
import { HttpClient } from '@angular/common/http';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'programmer-page',
    templateUrl: './programmer-page.component.html',
    styleUrls: ['./programmer-page.component.css']
})


export class ProgrammerPageComponent implements OnInit {

    languages = {
        basicWebkit: false, jQuery: false, typeScript: false, angular: false, react: false, vue: false,
        nodeJs: false, mySQL: false, noSQL: false, ruby: false, cPlusPlus: false, cSharp: false,
        java: false, perl: false
    };

    someRange=[0, 50];
    aux;

    tooltipStatus;
    sentLanguages: string[] = [];
    languageArray: string[] = [];
    //  = [
    //     'Basic Webkit (HTML, CSS, JS)', 'jQuery', 'typeScript', 'Angular', 'React', 'Vue', 'nodeJs',
    //     'mySQL', 'noSQL', 'Ruby', 'C++', 'C#', 'Java', 'Perl'
    // ];

    foundLanguages: string[] = [];
    addedLanguages: string[] = [];

    attributesTitle = 'Required knowledge:';
    leftButtonText = 'View Company Profile';
    leftButtonLink = '';
    rightButtonText = 'Apply for Job';
    rightButtonLink = '';

    projects: Card[] = [
        {
            picture: 'assets/shared/images/google.jpg',
            name: 'Google',
            rating: 2,
            comment: '$16/hr',
            title: 'Senior Tech Support',
            attributesTitle: this.attributesTitle,
            attributes: [
                'Basic Webkit (HTML, CSS, JS)',
                'English Language',
                'Lorem Ipsum'
            ],
            description: `
    Looking for Patels to solve this lorem ipsum dolor sit amet, consectetur
    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
            leftButtonText: this.leftButtonText,
            leftButtonLink: this.leftButtonLink,
            rightButtonText: this.rightButtonText,
            rightButtonLink: this.rightButtonLink
        },

        {
            picture: 'assets/shared/images/facebook.png',
            name: 'Facebook',
            rating: 4.5,
            comment: '$28/hr',
            title: 'Middle React Developer',
            attributesTitle: this.attributesTitle,
            attributes: [
                'Basic Webkit (HTML, CSS, JS)',
                'jQuery',
                'React',
                'English Language'
            ],
            description: `
    We are looking for a capable person with experience to join our React development team.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
    laboris nisi ut aliquip ex ea commodo consequat.`,
            leftButtonText: this.leftButtonText,
            leftButtonLink: this.leftButtonLink,
            rightButtonText: this.rightButtonText,
            rightButtonLink: this.rightButtonLink
        },

        {
            picture: 'assets/shared/images/patel.jpg',
            name: 'Patel',
            rating: 3,
            comment: '$58/hr',
            title: 'Wife',
            attributesTitle: this.attributesTitle,
            attributes: [
                'Cooking',
                'Satisfy in Bed',
                'Smart'
            ],
            description: `
    Looking for wife to share this lorem ipsum dolor sit amet with me, show bobs and vegana,
    consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
            leftButtonText: this.leftButtonText,
            leftButtonLink: this.leftButtonLink,
            rightButtonText: this.rightButtonText,
            rightButtonLink: this.rightButtonLink
        }
    ];

    projectOptions = [
        'Component Binding', 'Database Management', 'Mobile Application', 'Website - Backend', 'Website - Frontend'
    ];

    paymentFilters = [
        '< $10/h', '$10/h - $50/h', '$50/h - $100/h', '$100/h - $150/h', '$150/h - $200/h'
    ];

    constructor(private http: HttpClient, private service: ProgrammerPageService, private authService: AuthService) { }

    toggle(language) {
        this.service.toggle(language, this.languages);
    }

    toggleLanguageArray(hasAll) {
        if (hasAll) {
            this.sentLanguages = this.languageArray;
        } else {
            this.sentLanguages = [];
        }
    }
    search(language: string) {
        this.foundLanguages = [];
        if (language === '') {
            this.foundLanguages = this.languageArray;
        } else {
            for (const myLanguage of this.languageArray) {
                if (myLanguage.startsWith(language) && !this.foundLanguages.includes(myLanguage)) {
                    this.foundLanguages.push(myLanguage);
                }
            }
        }
    }

    addLanguage(language) {
        if (!this.addedLanguages.includes(language)) {
            this.addedLanguages.push(language);
        }
        this.foundLanguages = [];
    }

    removeLanguage(language) {
        const index = this.addedLanguages.indexOf(language);
        this.addedLanguages.splice(index, 1);
    }

    clearFound() {
        this.foundLanguages = [];
    }


    getLanguagesData() {

        // this.languageArray = this.authService.getLanguagesData();
        return this.http.get('https://ng-login-form.firebaseio.com/languages.json')
            .subscribe(
                (response) => {
                    console.log(response);

                    const languages = ((((JSON.stringify(response))
                    .replace(/\"/g, '')).replace('[', '')).replace(']', '')).toString();

                    const parsedLanguages = languages.split(',');
                    console.log(parsedLanguages);
                    this.languageArray = parsedLanguages;
                },
                (error) => console.log(error)
            );
    }
    setLanguagesData(message) {
        return this.http.post('https://ng-login-form.firebaseio.com/languages.json?auth=' + this.authService.token, JSON.stringify(message))
            .subscribe(
                (response) => {
                    console.log(response);
                },
                (error) => console.log(error)
            );
    }
    turnOffTooltips(){
        this.tooltipStatus=false;
    }
    updateVect(){
        this.aux = this.someRange;
        this.someRange = this.aux;
    }
    ngOnInit() {
        this.getLanguagesData();
    }
}
