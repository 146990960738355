import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {

    @Input('allFields') allFields: string[] = ['salut'];
    @Output('choice') choice = new EventEmitter<string>();
    results: string[];
    isCollapsed = true;

    search(language: string) {
        this.results = [];
        if (language === '') {
            this.results = this.allFields;
        } else {
            for (const myLanguage of this.allFields) {
                if (myLanguage.startsWith(language) && !this.results.includes(myLanguage)) {
                    this.results.push(myLanguage);
                }
            }
        }
    }
    constructor() { }

    ngOnInit() {
        this.results = this.allFields;
    }

    addResult(result) {
        this.choice.emit(result);
    }

    clearFound() {
        this.results = [];
    }

    toggleCollapse() {
        // this.clearFound();
        if (this.results.length === 0) {
            this.results = this.allFields;
        } else {
            this.clearFound();
        }
    }
}
