import { AuthService } from '../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsernameValidators } from '../validators/username.validators';
import { PasswordValidators } from '../validators/password.validators';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

    formType = false;

    programmerForm = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.email, Validators.required], UsernameValidators.shouldBeUnique),
        password: new FormControl('', [Validators.required, PasswordValidators.strength, PasswordValidators.minLength]),
        confirmPassword: new FormControl('', Validators.required)
    }, PasswordValidators.checkConfirm);

    companyForm = new FormGroup({
        companyName: new FormControl('', Validators.required),
        cui: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.email, Validators.required], UsernameValidators.shouldBeUnique),
        address: new FormControl('', Validators.required),
        password: new FormControl('', [Validators.required, PasswordValidators.strength, PasswordValidators.minLength]),
        confirmPassword: new FormControl('', Validators.required)
    }, PasswordValidators.checkConfirm);

    constructor(private authService: AuthService) { }

    toggleForm(showCompany: boolean) {
        this.formType = showCompany;
    }

    get programmerFirstName() {
        return this.programmerForm.get('firstName');
    }

    get programmerLastName() {
        return this.programmerForm.get('lastName');
    }

    get programmerEmail() {
        return this.programmerForm.get('email');
    }

    get programmerPassword() {
        return this.programmerForm.get('password');
    }

    get programmerConfirmPassword() {
        return this.programmerForm.get('confirmPassword');
    }

    //
    //

    get companyName() {
        return this.companyForm.get('companyName');
    }

    get cui() {
        return this.companyForm.get('cui');
    }

    get companyEmail() {
        return this.companyForm.get('email');
    }

    get companyAddress() {
        return this.companyForm.get('address');
    }

    get companyPassword() {
        return this.companyForm.get('password');
    }

    get companyConfirmPassword() {
        return this.companyForm.get('confirmPassword');
    }
    ngOnInit() {
    }

    // invalid(){
    //     return ( this.programmerEmail.invalid || this.programmerFirstName.invalid || 
    //              this.programmerLastName.invalid || this.programmerPassword.invalid || 
    //              this.programmerPassword.errors.strength || !this.programmerPassword.errors.minLength || !this.programmerForm.hasError('checkConfirm') );
    // }

    signUp() {

        // Firebase auth
        const email = this.programmerEmail.value;
        const password = this.programmerPassword.value;

        console.log(email);

        this.authService.SignUpUser(email, password);

        // EXTERNAL SERVICE auth

        // let input= new FormData();
        // if(this.form.status=="INVALID"){
        //    this.form.setErrors({
        //      invalidLogin : true
        //    });
        //  }
        //  else

        //  input.append('email',this.form.get('account.email').value);
        //  input.append('password',this.form.get('account.password').value);
        //  input.append('token','ararar');
        //  this.http.post('http://unicanvas.ro/freelance/client_register.php',input)
        //  .subscribe(token=>{

        //   console.log(token);
        //  })
        //  ;

    }
}
