import { Component, Input, OnInit } from '@angular/core';

export class Card {
  picture: string;
  name: string;
  rating: number;
  comment: string;
  title: string;
  attributesTitle: string;
  attributes: string[];
  description: string;
  leftButtonText: string;
  leftButtonLink: string;
  rightButtonText: string;
  rightButtonLink: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input('cardData') cardData: Card;

  constructor() { }

  ngOnInit() {
  }
}
