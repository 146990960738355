import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditPageSwitchService } from '../../services/edit-page-switch.service';

@Component({
  selector: 'app-edit-qualifications',
  templateUrl: './edit-qualifications.component.html',
  styleUrls: ['./edit-qualifications.component.css']
})
export class EditQualificationsComponent implements OnInit {

  profileLink: string = "/profile";

  editQualForm = new FormGroup({
    qualName: new FormControl(''),
    qualYear: new FormControl(''),
    orgName: new FormControl(''),
    qualDesc: new FormControl('')
  });

  get qualName() {
    return this.editQualForm.get('qualName');
  }
  get qualYear() {
    return this.editQualForm.get('qualYear');
  }
  get orgName() {
    return this.editQualForm.get('orgName');
  }
  get qualDesc() {
    return this.editQualForm.get('qualDesc');
  }

  constructor(private _switchService: EditPageSwitchService) {}
  fireSwitch(item: number) {
    console.log('selected page ' + item);
    this._switchService.changeItem(item);
  }

  ngOnInit() {
  }

}
